import React from "react"

const SectionHeader = ({ headerText }) => {
  return (
    <div className="py-2 mt-3 mb-4 flex justify-start items-center border-b w-full border-gray-400">
      <h1>{headerText}</h1>
    </div>
  )
}

export default SectionHeader
